<template>
<div class="containerChart">
  <div class="col-md-12 chartHeader border d-flex justify-content-between align-items-center p-0 itemsPosition">
    <div class="col-md-6 p-0">
      <h2 class="m-0">% Serviços mais Vendidos</h2>
    </div>
    <div class="col-md-6 px-0 py-md-0 py-3 d-flex align-items-center justify-content-between justify-content-md-around itemsPosition">
      <ButtonToggle :activo="activoPieCurrent" @toggleConvert="toggleBtn" />
      <btnFilter :positionIcon="'justify-content-center'" :positionContainer="'leftCenterMaste'" :show="showModal" @openFilter="showModalFilter" @filter="filter(fDate, fTenantId)" @filterClear="filter('0', '0')" :classBtn="'filterBtn'" class=" itensFilterbtn">
        <label class="labels" id="labelOrange">Mês</label>
        <input type="month" class="input" v-model="fDate" />
        <label class="labels" for="Unidades">Unidade</label>
        <div class="input_default" v-if="get_tenants.load">
          <div class="spinner">
            <half-circle-spinner :animation-duration="1000" :size="25" color="#f38235" />
          </div>
        </div>
        <select v-else class="select" name="Unidades" id="Unidades" v-model="fTenantId">
          <option value=""></option>
          <option v-for="item in filterTenants" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </btnFilter>
    </div>
    <!-- <div class="col-md-2 classMobileShow">
      <btnFilter :positionIcon="'justify-content-start'" :positionContainer="'left_top'" :show="showModal" @openFilter="showModalFilter" @filter="filter(fDate, fTenantId)" @filterClear="filter('0', '0')" :classBtn="'filterBtn'">
        <label class="labels" id="labelOrange">Mês</label>
        <input type="month" class="input" v-model="fDate" />
        <label class="labels" for="Unidades">Unidade</label>
        <div class="input_default" v-if="get_tenants.load">
          <div class="spinner">
            <half-circle-spinner :animation-duration="1000" :size="25" color="#f38235" />
          </div>
        </div>
        <select v-else class="select" name="Unidades" id="Unidades" v-model="fTenantId">
          <option value=""></option>
          <option v-for="item in filterTenants" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </btnFilter>
    </div> -->
  </div>
  <div class="container p-0" v-if="dataValuePie.length">
    <div class="col-md-12">
      <pie :chart-data="datacollection" :styles="myStyles"></pie>
    </div>
    <div class="mt-2">
      <div v-for="(item, index) in labelServicePieNameFull" :key="item" class="d-flex align-items-center mb-2">
        <div class="boxRadius d-flex align-items-center justify-content-center" :style="`background:${colors[index]}`"></div>
        <p class="m-0 ml-2 labelChart">{{ item }}</p>
      </div>
    </div>
  </div>
  <div v-else class="notSalles">
    Não encontrado
  </div>
</div>
</template>

<script>
import ButtonToggle from "@/components/metas/buttons/button_toggle.vue";
import {
  HalfCircleSpinner
} from "epic-spinners";
import btnFilter from "@/components/filters/dropdownFilter.vue";
import Pie from "./Pie.js";
import FilterMaster from "./filterMaster.vue";
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  props: {
    dataValuePie: {
      type: Array,
      default: []
    },
    labelServicePieNameFull: {
      type: Array,
      default: []
    },
    activoPieCurrent: {
      type: String,
      default: "money"
    }
  },
  components: {
    btnFilter,
    Pie,
    FilterMaster,
    HalfCircleSpinner,
    ButtonToggle
  },
  data() {
    return {
      showModal: false,
      datacollection: null,
      colors: [
        "rgba(243,130,53,1)",
        "rgba(224,166,56,1)",
        "rgba(37, 211, 102, 1)",
        "rgba(173, 216, 230, 1)",
        "rgba(36,116,178,1)"
      ],
      fDate: "",
      fTenantId: ""
    };
  },
  mounted() {
    this.datacollection = {
      datasets: [{
        backgroundColor: this.colors,
        data: this.dataValuePie
      }]
    };
    this.toggleBtn("money");
  },
  methods: {
    ...mapActions(["GetDashboardServicesSold"]),
    showModalFilter(param) {
      if (param && this.showModal) {
        this.showModal = false;
      } else if (!param && this.showModal) {
        this.showModal = param;
      } else {
        this.showModal = param;
      }
    },
    filter(date, tenantId) {
      if (!date && !tenantId) {
        this.toast(
          "Favor informar um dos parametros para a consulta",
          this.$toast.error
        );
        return;
      }

      this.GetDashboardServicesSold({
        date: date && date != "0" ? date : "",
        tenantId: tenantId && tenantId != "0" ? tenantId : "",
        categoryId: ""
      });
    },
    toggleBtn(changeValue) {
      this.$emit("update:activoPieCurrent", changeValue);
    }
  },
  computed: {
    ...mapGetters(["get_tenants"]),
    myStyles() {
      return {
        height: "80.5%",
        width: "80.5%",
        position: "relative",
        margin: "0 auto"
      };
    },
    filterTenants() {
      if (this.get_tenants.activeTenants) {
        return this.get_tenants.activeTenants.filter(x => x.type === 0);
      }
      return [];
    }
  }
};
</script>

<style scoped>
.containerChart {
  background: #fff;
  font-weight: 400;
  border-radius: 12px;
  padding: 14px;
  height: 465px;
}

@media (max-width: 1115px) {
  .containerChart {
    height: 465px;
  }
}

@media (max-width: 991px) {
  .containerChart {
    height: auto;
  }
}

.chartHeader h1 {
  font-size: 16px;
  color: #2474b2;
}

.chartHeader h2 {
  font-size: 14px;
  color: #2474b2;
}

.chartHeader p {
  font-size: 10px;
  color: #797984;
  margin: 0 10px 0 0;
}

.border {
  border: none !important;
  border-bottom: 1px solid #3f88c1 !important;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.boxRadius {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: red;
}

.labelChart {
  font-size: 9px !important;
}

.notSalles {
  font-size: 12px;
  text-align: center;
  margin: 40px;
  color: #797984;
}

.classMobileShow {
  display: none;
  width: auto !important;
}

.showFilterButton {
  display: block;
}

.itensFilterbtn {
  width: auto !important;
}

@media (min-width: 768px) {
  .classMobileShow {
    display: block;
  }

  .showFilterButton {
    display: none;
  }

  .itemsPosition {
    position: relative;
    top: -3px;
  }
}
</style>
