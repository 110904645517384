<template>
  <div>
    <div class="dropdown dropup">
      <button
        class="btnFilter"
        id="dropdownMenu2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="@/assets/image/filter/iconFilter.svg" alt="iconFilter" />
      </button>
      <!-- <div class="dropdown-menu p-0" aria-labelledby="dropdownMenu2" >
            <FiltersCp  />
         </div> -->
    </div>
  </div>
</template>

<script>
import FiltersCp from "@/components/filters/dropdownFilter.vue";
export default {
  components: {
    FiltersCp
  }
};
</script>

<style>
.btnFilter {
  border: 1px solid #ffffff;
  background: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  outline: none !important;
  border-radius: 5px;
  padding: 10px;
}

.btnFilter:hover {
  background: #98c4e634;
  border: 1px solid #98c4e6;
}

.btnFilter img {
  width: 20px;
}
</style>
