<template>
<div class="containerMainFilter overlayBackground" v-click-outside="closeModal">
  <div class="d-flex" :class="positionIcon">
    <button :class="classBtn" @click="$emit('openFilter', true)">
      <font-awesome-icon icon="fa-solid fa-filter" />
    </button>
  </div>
  <transition name="fade">
    <div v-if="show" class="containerFIlter" :class="positionContainer">
      <div>
        <h2 class="titleMain m-0">Filtrar por:</h2>
        <hr class="hr" />
      </div>
      <div class="d-flex flex-column">
        <slot></slot>
      </div>
      <div>
        <button class="sendFilter" @click="$emit('filter')">
          Filtrar
        </button>
        <button class="sendFilter clear" @click="$emit('filterClear')">
          Limpar
        </button>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    dropdown: {
      type: String,
      default: ""
    },
    positionIcon: {
      // posição do icone de filtro params: [justify-content = padrão, justify-content-center = centro, justify-content-end = lado direito]
      type: String,
      default: "justify-content"
    },
    positionContainer: {
      // posição do conteudo params: [left, center]
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    },
    classBtn: {
      type: String,
      default: "filterBtnDefault"
    }
  },
  methods: {
    closeModal() {
      this.$emit("openFilter", false);
    }
  },

};
</script>

<style>
.filterBtnDefault {
  background: #3f88c1;
  color: white;
  border-radius: 6px;
  border: none;
  padding: 10px;
  height: 38px;
  width: 38px;
  outline: none !important;
  transition: 0.2s ease-in-out;
}

.filterBtnDefault:hover {
  background: #3e80b3;
  color: #b8d7f0;
}

.filterBtn {
  background: transparent;
  color: #b8d7f0;
  border-radius: 6px;
  border: none;
  padding: 10px;
  height: 38px;
  width: 38px;
  outline: none !important;
  transition: 0.2s ease-in-out;
}

.filterBtn:hover {
  color: #f38235;
  background: #f0ececfd;
}

.titleMain {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #a5a6ad;
  padding: 0 0 11px;
}

.hr {
  background-color: #a5a6ad9e;
  height: 2px;
  border: none;
  margin: 0 0 10px;
}

.containerFIlter {
  width: 268px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  position: absolute;
  top: 45px;
  z-index: 99999;
  padding: 15px;
}

.containerMainFilter {
  width: 268px;
  position: relative;
}

.left {
  left: -85%;
}

.left_top {
  top: -40%;
  left: -102%;
}

.center {
  left: -37%;
}

.leftCenterMaste {
  left: -500%;
}
.leftCenterMasteTwo {
  left: -500%;
}

.labels {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2474b2;
}

.containerMainFilter .select,
.containerMainFilter .input {
  height: 38px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e9e9eb;
  border-radius: 6px;
  margin: 0 0 10px;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.containerMainFilter .select option {
  height: 38px;
  padding: 10px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e9e9eb;
  margin: 0 0 10px;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.clear {
  background: #f34b35 !important;
}

.containerMainFilter .sendFilter {
  background: #f38235;
  border-radius: 6px;
  height: 38px;
  width: 100%;
  color: #fff;
  margin: 10px 0 0;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.containerMainFilter .sendFilter:hover {
  background: #d47536;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.checkbox {
  cursor: pointer;
  width: 20px;
}
@media screen and (min-width:395px){
  .leftCenterMasteTwo {
    left: -273%;
  }
  .leftCenterMaste {
    left: -605%;
}
}
</style>
