import { Pie, mixins } from "vue-chartjs";
//import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
   extends: Pie,
   namespaced: true,
   mixins: [mixins.reactiveProp],
  //  plugins: [ChartDataLabels],
   props: ["chartData"],
   data() {
      return {
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                let sum = 0;
                dataset.data.map(x => {
                  sum += x
                });
                var currentValue = dataset.data[tooltipItem.index];
                return `${(currentValue*100 / sum).toFixed(2)}%`;
              }
            }
          }
      }
    }
  },
  mounted() {
      //this.addPlugin(ChartDataLabels);
      this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartData() {
      if (!this.chartData.length) {
        this.renderChart(this.chartData, this.options);
      }
    }
  }
};
