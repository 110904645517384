<template>
<div class="containerChart">
  <div class="chartHeader border d-flex justify-content-between align-items-center">
    <div class="col-md-12 d-flex testeOver">
      <div class="col-md-4 mt-1">
        <h1>Crescimento</h1>
      </div>
      <div class="col-md-6 mt-2 d-md-flex classMobileShow">
        <div class="col-md-6">
          <div class="row">
            <div class="box orange"></div>
            <p class="mt-1">Ano Atual {{ year ? `(${year})` : "" }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="box blue"></div>
            <p class="mt-1">
              Ano Anterior {{ lastYear ? `(${lastYear})` : "" }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-1 ml-5 ">
        <btnFilter :positionIcon="'justify-content-start'" :positionContainer="sizeWindow > 767 ? 'left_top' : 'leftCenterMasteTwo'" :show="showModal" @openFilter="showModalFilter" @filter="filter(fDate, fTenantId)" @filterClear="filter('0', '0')" :classBtn="'filterBtn'" class="btnFilterItem">
          <label class="labels" for="Unidades">Unidade</label>
          <select class="select" name="Unidades" id="Unidades" v-model="fTenantId">
            <option value=""></option>
            <option v-for="item in filterTenants" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
          <label class="labels" id="labelOrange">Mês</label>
          <input type="month" class="input" v-model="fDate" />
        </btnFilter>
      </div>
    </div>
  </div>
  <div>
    <chart v-if="dataCurrent.length || dataOldNetProfit.length" :chart-data="datacollection" :styles="myStyles" />
    <div v-else class="notgrowth">
      Não encontrado
    </div>
  </div>
</div>
</template>

<script>
import btnFilter from "@/components/filters/dropdownFilter.vue";
import Chart from "@/chart/chart";
import FilterMaster from "./filterMaster.vue";
import {
  mapActions,
  mapGetters
} from "vuex";
export default {
  props: {
    dataCurrent: {
      type: Array,
      default: []
    },
    dataOldNetProfit: {
      type: Array,
      default: []
    },
    months: {
      Type: Array,
      default: []
    },
    year: {
      type: Number,
      default: undefined
    },
    lastYear: {
      type: Number,
      default: undefined
    }
  },
  components: {
    Chart,
    FilterMaster,
    btnFilter
  },
  data() {
    return {
      sizeWindow: 0,
      datacollection: null,
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        plugins: {
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              weight: "bold",
              size: 16
            }
          }
        }
      },
      showModal: false,
      fDate: "",
      fTenantId: ""
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.datacollection = {
      labels: this.months,
      datasets: [{
          label: "",
          borderColor: "rgba(243, 130, 53, 0.9)",
          backgroundColor: "rgba(251, 166, 6, 0.783)",
          borderWidth: "1",
          barPercentage: 0.6,
          data: this.dataCurrent,
          // data: this.dataCurrent,
          datalabels: {
            display: false
          }
        },
        {
          label: " ",
          borderColor: "rgb(152, 196, 230)",
          backgroundColor: "rgba(121, 176, 219, 0.36)",
          borderWidth: "2",
          barPercentage: 0.6,
          data: this.dataOldNetProfit,
          // data: this.dataOldNetProfit,
          datalabels: {
            display: false
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_tenants"]),
    myStyles() {
      return {
        height: "70%",
        width: "100%",
        position: "relative"
      };
    },
    filterTenants() {
      if (this.get_tenants.activeTenants) {
        return this.get_tenants.activeTenants.filter(x => x.type === 0);
      }
      return [];
    }
  },
  methods: {
    ...mapActions(["GetDashboardMasterGraphicColumn"]),
    showModalFilter(param) {
      if (param && this.showModal) {
        this.showModal = false;
      } else if (!param && this.showModal) {
        this.showModal = param;
      } else {
        this.showModal = param;
      }
    },
    filter(date, tenantId) {
      if (!date && !tenantId) {
        this.toast(
          "Favor informar um dos parametros para a consulta",
          this.$toast.error
        );
        return;
      }
      if (date && date != "0") {
        let myYear = Number.parseInt(date.split("-")[0]);
        this.$emit("update:year", myYear);
        this.$emit("update:lastYear", myYear - 1);
      } else {
        this.$emit("update:year", undefined);
        this.$emit("update:lastYear", undefined);
      }
      this.GetDashboardMasterGraphicColumn({
        date: date && date != "0" ? date : "",
        tenantId: tenantId && tenantId != "0" ? tenantId : "",
        categoryId: ""
      });
    }
  }
};
</script>

<style scoped>
.containerChart {
  background: #fff;
  font-weight: 400;
  border-radius: 12px;
  padding: 8px;
}

.chartHeader h1 {
  font-size: 16px;
  color: #2474b2;
}

.chartHeader .box {
  height: 13px;
  width: 13px;
  margin: 0 5px 0 0;
}

.orange {
  background: rgba(243, 130, 53, 0.87);
  border: 1px solid #f38235;
}

.blue {
  background: rgba(121, 176, 219, 0.36);
  border: 1px solid #98c4e6;
}

.chartHeader p {
  font-size: 10px;
  color: #797984;
  margin: 0 10px 0 0;
}

.border {
  border: none !important;
  border-bottom: 1px solid #3f88c1 !important;
  padding-bottom: 5px;
  margin-bottom: 5px;

}

.notgrowth {
  font-size: 12px;
  text-align: center;
  margin: 40px;
  color: #797984;
}

/* .testeOver {
  overflow: hidden;
} */
.classMobileShow {
  display: none;
}

.btnFilterItem {
  width: auto !important;
}
@media (min-width: 768px) {
  .classMobileShow {
    display: block;
  }
}
</style>
